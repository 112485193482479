<template>
    <PageContent class="mainmenu min-vh-100  d-flexp-3">
        <NotReady v-if="!ready" />
        <div v-else class="order-edit-tables">
            <Help />
            <Header :headerClone="headerClone" :updateRequests="updateRequests" :header="header" :itemListCodes="itemListCodes" :items="items" v-on:togglePopovers="togglePop"  v-on:changeHistoryDisplay="getOrderItems" v-on:updateHeader="updateHeader"></Header>
            <br>
            <div class="mobile-sort bg-light border mb-3  p-1 pt-3 ps-2 pe-2 d-block d-lg-none">
                <label for="">Sort By</label>
                <select v-model="filter.orderBy" class="form-control mb-3" id="">
                    <option value="itemno">Item Number</option>
                    <option value="pricecode">Price Code</option>
                    <option value="qty">Order Quantity</option>
                    <option value="healthy">Healthy</option>
                    <option value="msrp">MSRP</option>
                    <option value="UPC">UPC Code</option>
                    <option value="removed">Removed from Menu</option>
                    <option value="userdef">User Defined</option>
                    <option value="tray">Tray Size</option>
                    <option value="life">Shelf Life</option>
                    <option value="pullcode">Label Date</option>
                    <option value="msrp">MSRP Code</option>
                    <option value="basecost">Base Cost</option>
                    <option value="EXTENDED">Extend</option>
                    <option value="H1">H1</option>
                    <option value="H2">H2</option>
                    <option value="H3">H3</option>
                    <option value="H4">H4</option>
                    <option value="H5">H5</option>
                    <option value="H6">H6</option>
                    <option value="H7">H7</option>
                    <option value="H8">H8</option>
                    <option value="H9">H9</option>
                    <option value="H10">H10</option>
                    <option value="glutenfree">Gluten Free</option>
                    <option value="vegetarian">Vegetarian</option>
                    <option value="vegan">Vegan</option>
                    <option value="lenten">Lenten</option>
                    <option value="pork">No Pork</option>
                </select>
                <label for="">Sort Order</label>
                <select v-model="filter.order" class="form-control mb-3" id="">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                </select>
            </div>
            <ScrollingTable @keydown="focusInput" tablebodyid="orderTable" :scroll="$store.state.localStorage.user['Order Entry Scroll Items']" v-show="$store.state.localStorage.user['Order Entry Group Items']==0"  :style="`position:sticky; top: 0; left: 0`">
                <template v-slot:table-header>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <TableHead :filter="filter" :search="search" />
                    </table>
                </template>
                <template v-slot:table-body>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <TableBody :search="search" :indx="0" :header="header" :group="items" :past_add_cut="past_add_cut" v-on:saveItem="Save($event)" v-on:removeFromMenu="removeFromMenu($event)" />
                    </table>
                </template>
            </ScrollingTable>
            <ScrollingTable @keydown="focusInput" tablebodyid="orderTable" :scroll="$store.state.localStorage.user['Order Entry Scroll Items']" v-show="$store.state.localStorage.user['Order Entry Group Items']==1"  :style="`position:sticky; top: 0; left: 0`">
                <template v-slot:table-header>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <TableHead :filter="filter" :search="search" />
                    </table>
                </template>
                <template  v-slot:table-body>
                    <div id="accordion" class="accordion">
                        <div class="accordion-item mt-0 mb-0" id="accordionExample" v-for="(group,groupName,indx) in groupedItems" :key="indx">
                                <h5 class="accordion-header p-0 border" :id="`heading${indx}`">
                                    <button class="accordion-button  text-white text-decoration-none p-2 rounded-0" tabindex="-1" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${indx}`" aria-expanded="true" :aria-controls="`#collapse${indx}`" style="background-color: var(--bs-gray-600); ">
                                        {{groupName.replace(/^\d+/, '')}}
                                    </button>
                                </h5>
                                <div :id="`collapse${indx}`" :class="`accordion-collapse   ${indx==0?'show':'show'}  border-0`" :aria-labelledby="`heading${indx}`" data-bs-parent="#accordion">
                                    <div class="accordion-body border-0 p-0">
                                        <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                                            <TableBody :search="search" :indx="indx" :header="header" :group="group" :past_add_cut="past_add_cut"  v-on:saveItem="Save($event)" v-on:removeFromMenu="removeFromMenu($event)"   />
                                        </table>
                                    </div>
                                </div>
                        </div>
                    </div>
                </template>
            </ScrollingTable>
            <br>
            <Footer  :header="header" :itemListCodes="itemListCodes" :items="items"></Footer>
            <History :items="items" :allItems="allItems" :header="header" />

        </div>
        
    </PageContent>
  </template>
  
  <script>
    import Header from '@/components/Orders/Header.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import History from '@/components/Orders/History.vue';
    import TableHead from '@/components/Orders/TableHead.vue';
    import TableBody from '@/components/Orders/TableBody.vue';
    import Footer from '@/components/Orders/Footer.vue';
    import Select from '@/components/Select.vue'
    import Help from '@/components/Help.vue'
    import ScrollingTable from '@/components/Table/ScrollingTable.vue';

    import VueSimpleAlert from "vue3-simple-alert";
import axios from 'axios';

    export default {
        name: 'OrdersEdit',
        components: {PageContent, PageTitle,  NotReady, Header,History,TableHead, TableBody, Footer,Select, ScrollingTable, Help},
        data(){
            return {
                _:_,
                headerClone:{},
                UsedOnDay:'MON',
                ready:false,
                disabled:false,
                txtItemListCode:'',
                itemListCodes: [],
                firstDate:this.$route.query.FirstDate,
                lcsummarytype:'LOCATION',
                lchistorytype:'Last',
                allItems:[],
                items:[],
                groupedItems:{},
                totals:[],
                popovers:[],
                header:{},
                past_add_cut:false,
                location:{},
                updateRequests:[],
                performingUpdate:false,
                search:{
                    searchString:['true'],
                    'itemno':'',
                    'itmdesc':'',
                    'pricecode':'',
                    'qty':'',
                    'healthy':'',
                    'msrp':'',
                    'UPC':'',
                    'userdef':'',
                    'tray':'',
                    'life':'',
                    'pullcode':'',
                    'msrpcode':'',
                    'ttl':'',
                    'H1':'',
                    'H2':'',
                    'H3':'',
                    'H4':'',
                    'H5':'',
                    'H6':'',
                    'H7':'',
                    'H8':'',
                    'H9':'',
                    'H10':'',
                    'glutenfree':'',
                    'vegetarian':'',
                    'vegan':'',
                    'lenten':'',
                    'basecost':'',
                    'EXTENDED':'',
                    'ttl':'',
                    'remove':''
                },
                filter:{
                    orderBy:'itemno',
                    order:'asc'
                },
                orderedGroups:_.orderBy(this.group, 'itemno','asc')
            }
        },
        watch:{
            'updateRequests':{
                deep:true,
                async handler(newVal){
                    if(this.performingUpdate) return;
                }
            },
            '$store.state.localStorage.user':{
                deep:true,
                handler:_.debounce(async function(newVal){
                    let req, res;
                    $('#order-column-chooser').find('fieldset').attr('disabled','disabled');
                    let formData = new FormData();
                    formData.append('user', JSON.stringify(this.$store.state.localStorage.user));
                    res = await axios.post(`${this.$store.state.api}/update_order_columns?lccustno=${this.$root.getCustomerNumber()}`,formData);
                    localStorage.setItem('user', JSON.stringify(newVal));
                    $('#order-column-chooser').find('fieldset').removeAttr('disabled');
                }, 1000, {trailing:true, leading:false}),
            },
            filter:{
                deep:true,
                handler(newVal){
                    console.log('blah')

                    if(this.$store.state.localStorage.user['Order Entry Group Items']){
                        switch(newVal.orderBy){
                            case 'OrderQty1':
                            case 'OrderQty2':
                            case 'OrderQty3':
                            case 'OrderQty4':
                            case 'OrderQty5':
                            case 'OrderQty6':
                            case 'OrderQty7':
                            case 'OrderQty8':
                            case 'OrderQty9':
                            case 'OrderQty10':
                            case 'EXTENDED':
                            case 'UPC':
                            case 'glutenfree':
                            case 'healthy':
                            case 'item_menued':
                            case 'itemno':
                            case 'lenten':
                            case 'life':
                            case 'msrp':
                            case 'msrpcode':
                            case 'qty':
                            case 'removed':
                            case 'showonmenu':
                            case 'ttl':
                            case 'vegan':
                            case 'vegetarian':
                            case 'tray':
                                for(let group in this.groupedItems){
                                    this.groupedItems[group] = _.orderBy(this.groupedItems[group], i=>+i[newVal.orderBy], newVal.order);
                                }    
                            
                            break;
                            default : 
                                for(let group in this.groupedItems){
                                    this.groupedItems[group] = _.orderBy(this.groupedItems[group], i=>i[newVal.orderBy].toLowerCase(), newVal.order);
                                }    
                        }
                    }
                    else if(!this.$store.state.localStorage.user['Order Entry Group Items']){
                            switch(newVal.orderBy){
                                case 'OrderQty1':
                                case 'OrderQty2':
                                case 'OrderQty3':
                                case 'OrderQty4':
                                case 'OrderQty5':
                                case 'OrderQty6':
                                case 'OrderQty7':
                                case 'OrderQty8':
                                case 'OrderQty9':
                                case 'OrderQty10':
                                case 'EXTENDED':
                                case 'UPC':
                                case 'glutenfree':
                                case 'healthy':
                                case 'item_menued':
                                case 'itemno':
                                case 'lenten':
                                case 'life':
                                case 'msrp':
                                case 'msrpcode':
                                case 'qty':
                                case 'removed':
                                case 'showonmenu':
                                case 'ttl':
                                case 'vegan':
                                case 'vegetarian':
                                case 'tray':
                                    this.items = _.orderBy(this.items, i=>+i[newVal.orderBy], newVal.order);  
                                break;
                                default : 
                                    this.items = _.orderBy(this.items, i=>i[newVal.orderBy].toLowerCase(), newVal.order);  
                            }
                    }
                },
            }
        },
        async mounted(){
            await Sleep(10);

            let req, res;
            
            // res = await axios.get(`${this.$store.state.api}/itemlistcodes?lccustno=${this.$root.getCustomerNumber()}`);
            // this.itemListCodes = _.uniqBy(res, 'item list code');

            if(this.$route.params.document_no=='create'){
                await this.getOrderItems();

            }
            else{
                await this.getOrderItems();
            }
            // if($('#orderTable'))

        },
        methods:{
            async focusInput(e){
                if($(e.target).parents('.orderTable').length){
                    let list = $('.orderTable [tabindex]:not([tabindex="-1"]):visible');
                    let activeIndex = [...list].findIndex(input=>input==document.activeElement);

                    switch(e.key){
                        case 'Tab':
                        case 'Enter':
                        case 'ArrowDown':
                            if(activeIndex!=-1&&list[activeIndex+1]) {
                                e.preventDefault();
                                list[activeIndex+1].focus();
                            }
                            
                        break;
                        case 'ArrowUp':
                            if(activeIndex!=-1&&list[activeIndex-1]) {
                                e.preventDefault();
                                list[activeIndex-1].focus();
                            }
                            else{
                                e.preventDefault();
                                $(e.target).parents('.orderTable').prev().find('tr').last().find('th').last().find('input').focus();

                            }
                        break;
                    }
                }
                // // if($(e.target).parents('.orderTable').length==0) return;
                // let activeElement = document.activeElement.tagName;
                // let $parent = $(e.target).parents('.orderTable');
                // let $list = $parent.find('.qty-field:visible');
                // let focus;
                // let nextFocus;
                // let prevFocus;
                // let action = '';
                // if(!$(document.activeElement).hasClass('qty-field')) return;
                // else{
                //     switch(e.key){
                //         case 'Tab':
                //         case 'ArrowDown':
                //         case 'Enter':
                //             action='next';
                //             e.preventDefault();

                //         break;
                //         case 'ArrowUp':
                //             action='prev';
                //             e.preventDefault();
                //         break;
                            
                //     }
                //     // console.log($list[0])
                //     // console.log(document.activeElement)
                //     // console.log($list[0]==document.activeElement)
                //     // console.log($list[1]==document.activeElement)
                //     let currentIndex = ([...$list].findIndex(i=>i==document.activeElement));
                //     // if(currentIndex==0) return;
                //     // if(currentIndex==$list.length) return;
                //     console.log(currentIndex)
                //     // $list[currentIndex+1].focus();
                //     // $list.filter(i=>console.log(i))

                //     return;
                //     setTimeout(()=>{

                //     },10);
                //     // for(var i = 0; i < $list.length; i++){
                //         // if($($list[i]).is(':focus')){
                //             // focus = $list[i];
                //             // nextFocus = $list[i+1];
                //             // prevFocus = $list[i-1];
                //         // }
                //     // }
                //     if(action=='next') $(nextFocus).focus();
                //     if(action=='prev') $(prevFocus).focus();
                //     console.log('hello')
                //     if(action=='prev'&&prevFocus==undefined){

                //         let matchingCells = ($(e.target).parents('.orderTable').prev().find(`table thead tr:nth-of-type(2) th`).filter(function(){
                //             return $(this).find('input[type="text"]').length > 0;
                //         }));
                //         $(_.last(matchingCells)).find('input[type="text"]').focus();
                //     }
                // }
            },
            btoa(string){
                return window.btoa(string);
            },
            async updateHeader(url){
                let req, res;
                this.ready = false;
                res = await axios.get(url);
                await Sleep(10);
                await this.getOrderItems();
            },
            getOrderItems(){
                return new Promise(async resolve=>{
                    this.ready = false;
                    let req, res;
                    res = await axios.get(`${this.$store.state.api}/orderedit?lccustno=${this.$root.getCustomerNumber()}&Location=${this.$route.params.location_no}&lcroute=${this.$route.params.route_no}&OrdrDte=${this.$route.query.FirstDate}&from=SALESORDER&lcorderno=${this.$route.params.document_no}&ShipToCustNo=${this.$route.query.ShipToCustNo}&HistoryDisplay=${this.$store.state.localStorage.user['Order Entry History Display']}`);
                    console.log(res);
                    if(this.$route.params.document_no=='create'){
                        this.$router.replace({params:{document_no:res.header.lcordrno.Nextordernumber}, query:this.$route.query});
                    }
                    this.ready = true;
                    this.itemListCodes = _.uniqBy(res.listcodes, 'item list code');
                    this.items = res.items;

                    this.allItems = res.items; //this is used to count history totals and stuff

                    this.items = _.filter(this.items,item=>item.item_menued||+item.qty>0);
                    
                    this.items = _.filter(this.items, item=>item.showonmenu||item.sectionname == 'Z-Not Menued');
                    this.items = [...this.items,..._.filter(this.allItems, item=>!item.showonmenu&&item.qty>0)];
                    this.items = _.uniqBy(this.items,'itemno');
                    this.items = (_.map(this.items, i=>({...i, ...{qty:+i.qty==0?'':parseInt(i.qty), origQty:+i.qty==0?'':parseInt(i.qty), tray:parseFloat(i.tray)}})))

                    if(!this.$store.state.localStorage.user['Menu Change']){
                        this.items = _.filter(this.items, item=>item.removed==0);
                    }
                    // console.log(this.items)
                    this.groupedItems = _.groupBy(this.items, 'sectionname');
                    this.header = res.header;
                    this.headerClone = {...this.header};
                    this.header.laqtyhist = this.header.laqtyhist.filter(hist=>hist[1]!='');
                    this.past_add_cut = res.past_add_cut;
                    this.ready = true;
                    await Sleep(10);
                    this.togglePop();
                    resolve();
                });
            },
            
            removeFromMenu:_.throttle(async function(item){
                let req , res;
                res = await axios.get(`${this.$store.state.api}/ordereditsaveremovefrommenu?lccustno=${this.$root.getCustomerNumber()}&lcordrno=${this.header.lcordrno.Nextordernumber}&lcitem=${item.itemno}&lcremoved=${item.removed}&lc_menuseqno=${this.header.lc_menuseqno}&lcitemlistcode=${this.header.lcitemlistcode}&lc_menucycle=${this.header.lc_menucycle}`);

            }, 300, {trailing:false, leading:true}),
            Save:_.throttle(async function(item){
                if(item.qty==item.origQty) return;
                if(isNaN(parseInt(item.qty))) item.qty = '';
                let result = true;
                let confirmQuantity = false;
                if(typeof item.qty == 'string'){
                    if(!item.qty.startsWith('#')){
                        if((item.qty) > 300){
                            confirmQuantity = true;
                        }
                    }
                    else{
                        item.qty = parseInt(item.qty.replace('#', ''));
                    }
                }
                if(confirmQuantity){
                    try{
                        result = await VueSimpleAlert.confirm(`Are you sure you want to order more than a quantity of 300 for ${item.itmdesc}?`, `Quantity Alert`);
                    } catch(err){
                        result = false;
                    }
                    if(!result) item.qty = '';
                }

                if(!result) return;

                let req , res;
                req = await axios.get(`${this.$store.state.api}/salesorderlineupdate?&ordrno=${this.$route.params.document_no}&itemno=${item.itemno}&qty=${item.qty}&lcbuttonsubmit=Update`);
                item.origQty = item.qty;
                
            }, 0, {trailing:false, leading:true}),
            togglePop(){           
                
                if(!this.$store.state.localStorage.user['Order Entry Use Popovers']){
                    for(let i = 0; i < this.popovers.length; i++){
                        this.popovers[i].dispose();
                    }

                    this.popovers = [];
                    let popoverElms = [];
                }
                else{
                    this.popovers = [];
                    let popoverElms = [];
                    popoverElms = [...document.querySelectorAll('[data-bs-toggle="popover"]')];
                    for(let i =  0; i < popoverElms.length; i++){
                        let elm = popoverElms[i];
                        this.popovers.push(new bootstrap.Popover(elm, {
                            trigger:'focus',
                            container:'body',
                            html:true
                        }));
                    }
                }
                

           
            }
        },
    }
  </script>
  <style lang="scss">
  .is-responsive{
    @media (max-width: 992px){
        .order-edit-tables{
            .accordion{
                .accordion-item{
                    border: 0 !important;
                    margin-bottom: 0.75rem !important;
                }
            }
            tbody{
                display:block;
                > tr{
                    display:grid;
                    grid-template-columns: 80px calc(100% - 80px);
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;
                    border: 1px solid #ADB5BD !important;
                    width: 100%;
                    > td{
                        width: 100%;
                        display:block;
                        order: 100;
                        grid-column: 1 / span 2;
                        text-align: left !important;
                        padding-top: 4px;
                        padding-bottom: 4px;
                        > *{
                            text-align: left !important;
                            display:inline-block !important;
                            vertical-align:middle;
                        }
                        &:empty{
                            // display:none;
                        }
                        &:before{
                            font-weight:bold;
                            margin-right: 0.5rem;
                        }
                        &.desc{
                            a{
                                white-space:nowrap !important;
                                text-overflow: ellipsis !important;
                                display:block;
                                width: 100%;
                                overflow:hidden;
                            }
                        }
                        &.pricecode{
                            &:before{
                                content: 'Price Code: ';
                            }
                        }
                        &.qty{
                            &:before{
                                content: 'Qty: ';
                            }
                        }
                        &.healthy{
                            &:before{
                                content: 'Healthy: ';
                            }
                        }
                        &.msrp{
                            &:before{
                                content: 'MSRP: ';
                            }
                        }
                        &.upccode{
                            &:before{
                                content: 'UPC Code: ';
                            }
                        }
                        &.removefrommenu{
                            &:before{
                                content: 'Remove: ';
                            }
                        }
                        &.userdef{
                            &:before{
                                content: 'User Def: ';
                            }
                        }
                        &.traysize{
                            &:before{
                                content: 'Tray Size: ';
                            }
                        }
                        &.shelflife{
                            &:before{
                                content: 'Shelf Life: ';
                            }
                        }
                        &.pullcode{
                            &:before{
                                content: 'Pull  Code: ';
                            }
                        }
                        &.msrpcode{
                            &:before{
                                content: 'MSRP Code: ';
                            }
                        }
                        &.basecost{
                            &:before{
                                content: 'Base Cost: ';
                            }
                        }
                        &.extended{
                            &:before{
                                content: 'Extended: ';
                            }
                        }
                        &.histtotal{
                            &:before{
                                content: 'Hst Ttl: ';
                            }
                        }
                        &.glutenfreen{
                            &:before{
                                content: 'Gluten Free: ';
                            }
                        }
                        &.vegetarian{
                            &:before{
                                content: 'Vegetarian: ';
                            }
                        }
                        &.vegan{
                            &:before{
                                content: 'Vegan: ';
                            }
                        }
                        &.lenten{
                            &:before{
                                content: 'Lenten: ';
                            }
                        }
                        @for $i from 1 through 10{
                            &.hist#{$i}{
                                display:inline-block !important;
                                width: auto !important;
                                &:empty{
                                    &:before{
                                        // content: 'H#{$i}: 0';
                                    }
                                }
                                &:before{
                                    content: 'H#{$i}: ';
                                }
                                
                            }
                        }
                        &.hist1, &.hist2, &.hist3, &.hist4, &.hist5, &.hist6, &.hist7, &.hist8, &.hist9, &.hist10{
                            // display:none !important;
                        }
                    }
                    &[class=""]{
                        grid-template-rows: 30px repeat(1, 1fr);
                        > td{
                            border: 0 !important;
                            &.desc{
                                grid-row: 1 / span 1;
                                grid-column: 1 / span 2;
                                width: 100%;
                                text-align: left !important;
                                font-weight:bold;
                                background-color:#ADB5BD;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                    &.has-order-itemno{
                        grid-template-rows: 20px 30px repeat(2, 1fr);
                        > td{
                            border: 0 !important;
                            &.desc{
                                order: 1;
                                width: 100%;
                                text-align: left !important;
                                font-weight:bold;
                                background-color:#ADB5BD;
                                grid-column: 1 / span 2;
                                grid-row: 1 / span 1;
                                display:flex;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                }
                            }
                            &.itemno{
                                order: 2;
                                width: 100%;
                                text-align: left !important;
                                background-color:#ADB5BD;
                                grid-column: 1 / span 2;
                                grid-row: 2 / span 1;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    font-size: 0.75rem !important;

                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                    &.has-order-image{
                        grid-template-rows: 30px repeat(2, 1fr);
                        > td{
                            border: 0 !important;
                            &.image{
                                order: 1;
                                width: 80px;
                                text-align: left !important;
                                font-weight:bold;
                                background-color:#ADB5BD;
                                grid-column: 1 / span 1;
                                grid-row: 1 / span 2;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                }
                            }
                            &.desc{
                                order: 2;
                                width: 100%;
                                text-align: left !important;
                                font-weight:bold;
                                background-color:#ADB5BD;
                                grid-column: 2 / span 1;
                                grid-row: 1 / span 2;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                }
                            }
                            
                        }
                    }
                    &.has-order-image.has-order-itemno{
                        grid-template-rows: 30px repeat(2, 1fr);
                        > td{
                            border: 0 !important;
                            &.desc{
                                order: 2;
                                width: 100%;
                                text-align: left !important;
                                font-weight:bold;
                                background-color:#ADB5BD;
                                grid-column: 2 / span 1;
                                grid-row: 1 / span 1;
                                display:flex;
                                align-items:flex-end;
                                padding-bottom: 0 !important;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                }
                            }
                            &.itemno{
                                order: 3;
                                width: 100%;
                                text-align: left !important;
                                background-color:#ADB5BD;
                                grid-column: 2 / span 1;
                                grid-row: 2 / span 1;
                                padding-top: 0px !important;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                    font-size: 0.75rem !important;
                                }
                            }
                        }
                    }
                    &:not(.has-order-image){
                        grid-template-rows: 30px repeat(1, 1fr);
                        > td{
                            border: 0 !important;
                            &.desc{
                                order: 2;
                                width: 100%;
                                text-align: left !important;
                                font-weight:bold;
                                background-color:#ADB5BD;
                                // grid-column: 2 / span 2;
                                // grid-row: 1 / span 1;
                                display:flex;
                                align-items:flex-end;
                                // padding-bottom: 0 !important;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    // padding-top: 5px;
                                    // padding-bottom: 5px;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                }
                            }
                            &.itemno{
                                order: 3;
                                width: 100%;
                                text-align: left !important;
                                background-color:#ADB5BD;
                                // grid-column: 2 / span 1;
                                // grid-row: 2 / span 1;
                                // padding-top: 0px !important;
                                a{
                                    color:#000;
                                    text-decoration: none;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    &:any-link{
                                        color:#000;
                                        text-decoration: none;
                                    }
                                    font-size: 0.75rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
  }
    
    .accordion{
        .accordion-item{
            .accordion-header{
                outline: none !important;
                box-shadow: none !important;
                .accordion-button{
                    outline: none !important;
                    box-shadow: none !important;
                    &:after{

                        filter: grayscale(1) invert(1);
                    }
                }
            }
        }
    }
    </style>